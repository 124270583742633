<template>
  <div class="all">
    <h1>{{ data.title }}</h1>
    <div class="row">
      <div class="types">
        <span v-for="item in ftypes">{{ item }}</span>
      </div>
      <div class="date">
        <span>发布日期：{{ fcreate }}</span>
        <span v-show="fedate">有效日期：{{ fedate }}</span>
      </div>
    </div>
    <div v-html="data.content" class="cont"></div>
    <div class="tips">
      免责声明：网站发布的原创及转载内容，仅供客户参考，不作为决策建议。原创内容版权归上海同鑫所有，转载需取得上海同鑫授权，且公司保留对任何侵权行为和有悖原创内容原意的引用行为进行追究的权利。转载内容来源于网络，目的在于传递更多信息，方便学习与交流，并不代表上海同鑫赞同其观点及对其真实性、完整性负责。
    </div>
    <div class="solid"></div>
    <Spin v-if="loading" fix>
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <div>加载中...</div>
    </Spin>
  </div>
</template>

<script>
export default {
  name: 'NewsInfo',
  props: {
    loading: Boolean,
    data: Object,
  },
  data() {
    return {}
  },
  computed: {
    fcreate() {
      return this.$handle.dateFormat(this.data.create, 'y/m/d')
    },
    fedate() {
      return this.$handle.dateFormat(this.data.effectiveDate, 'y/m/d')
    },
    ftypes() {
      let t = []
      let mName = this.data.marketName
      let tname = this.data.name
      if (mName && mName.length) {
        t.push(mName)
      }
      if (tname && tname.length) {
        t.push(tname)
      }
      t.push('同鑫资讯')
      return t
    },
  },
}
</script>

<style scoped>
.all {
  position: relative;
  width: 800px;
}

h1 {
  text-align: center;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.row {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
}

.date {
  font-size: 16px;
  color: #333333;
}

.date span:first-child {
  margin-right: 20px;
}

.date span:last-child {
  color: #e83033;
}

.types span {
  display: inline-block;
  width: 90px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #25449a;
  font-size: 14px;
  background: #dcedff;
  margin-right: 10px;
  border-radius: 3px;
}

.cont {
  padding: 30px 0;
  line-height: 30px;
  font-size: 14px;
  color: #000;
}
.tips {
  padding: 10px;
  width: 100%;
  color: #666;
  line-height: 25px;
  font-size: 14px;
  background-color: #e6ebf5;
}
.solid {
  margin-top: 10px;
  width: 100%;
  height: 1px;
  background-color: #e6ebf5;
}
</style>
